import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API, endpoints } from "../../api";
import { Loader } from "../../components";
import { useAuth } from "../../context/Auth";
import strings, { toastType } from "../../utils/strings";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const CheckoutForm = ({ setTermsError, termsChecked, goToSuccessPage, cardUI }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  // const [clientSecret, setClientSecret] = useState(false);

  const navigate = useNavigate();

  console.log("submitted || loading -> ", {
    submitted, loading
  })

  const params = useParams();
  const isUpdateCard = params?.type === 'new-card'

  const { checkSubscription, loginRedirectUrl, subscriptionData, showToast, user } = useAuth();


  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log('handleSubmit -> ', event)

    if (!user?.user?.country || !user?.user?.country_code) {
      return showToast({ text: strings.noCountry, type: toastType.ERROR });
    }

    if (!termsChecked && !isUpdateCard) {
      return setTermsError(true);
    }

    if (submitted || loading) return; // Prevent multiple submissions
    setSubmitted(true); // Disable the button immediately

    setLoading(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      if (error) {
        setLoading(false);
        setSubmitted(false); // Re-enable the button in case of an error
        return;
      }

      console.log("paymentMethod -> ", paymentMethod)

      if (paymentMethod) {
        const cardElement = elements.getElement(CardElement);
        let { token } = await stripe.createToken(cardElement);

        console.log("token -> ", token)


        var data = new FormData();
        data.append("stripeToken", token?.id);
        data.append("changeCard", 1);

        API.post({ route: isUpdateCard ? endpoints.attachCard : endpoints.SUBSCRIPTION, data }).then(async (data) => {

          console.log("data -> ", data?.status, data)
          if (!data?.status) {
            showToast({
              text: data?.message,
              type: toastType.ERROR,
            });
            setLoading(false);
            setSubmitted(false); // Re-enable the button on failure
            return;
          }

          if (isUpdateCard) {
            showToast({
              text: 'Card Updated'
            });
            navigate('/subscription-status')
            setTimeout(() => window.location.reload(), 300)
            return
          }

          const { type, clientSecret, subscription_mode, feed_id } = data?.result || {};

          if (subscription_mode) {
            if (type === 'setup') {
              const { paymentIntent, error } = await stripe.confirmCardSetup(
                clientSecret,
                {
                  payment_method: {
                    card: {
                      token: token?.id,
                    },
                  },
                },
              );
              if (paymentIntent?.status === "succeeded") {
                goToSuccessPage(feed_id);
              } else if (error?.message) {
                showToast({
                  text: error?.message,
                  type: toastType.ERROR,
                });
                setSubmitted(false); // Re-enable the button on error
              }
            } else {
              try {
                const { paymentIntent, error } = await stripe.confirmCardPayment(
                  clientSecret,
                  {
                    payment_method: paymentMethod?.id,
                  },
                );
                if (paymentIntent?.status === "succeeded") {
                  goToSuccessPage(feed_id);
                } else if (error?.message) {
                  showToast({
                    text: error?.message,
                    type: toastType.ERROR,
                  });
                  setSubmitted(false); // Re-enable the button on error
                }
              } catch (e) {
                console.log("error in payment -> ", e)
                showToast({
                  text: 'Subscription renewed successfully',
                });
                navigate('/subscription-status')
                setTimeout(() => window.location.reload(), 300)
              }
            }
          } else {
            goToSuccessPage(feed_id);
          }
        }).catch((e) => {
          console.log("error in card -> ", e?.response?.data, e)

          showToast({
            text: e?.response?.data?.message?.split('.')?.[0] ?? 'Wrong card entered',
            type: toastType.ERROR,
          });
          setLoading(false);
          setSubmitted(false); // Re-enable the button on failure
        })
          .finally(() => setLoading(false));
      } else {
        setSubmitted(false); // Re-enable the button if no payment method was created
        setLoading(false);
      }
    } catch (e) {
      console.log("Error => ", e);
      setSubmitted(false); // Re-enable the button on exception
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="stripe-form pt-3" id="step2">
          <div className="form-row">
            <label htmlFor="card-element text-red" style={{ fontSize: 16, color: '#f00', fontWeight: '500' }}>
              Type your Card Number and Expiry Date below:
            </label>
          </div>

          <div className={`${cardUI ? 'card-design' : ''} mb-4`}>
            <CardElement
              className="StripeElement"
              options={{
                style: {
                  // complete: {
                  //   color: '#26783c',
                  // },
                  base: {
                    padding: 10,
                    lineHeight: 2,
                    // backgroundColor: 'rgba(0,0,0,.1)',
                    fontSize: "18px",
                    color: cardUI ? "#fff" : '#000',
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
            <div className="card-submit">
              <button
                disabled={submitted || loading}
                className="ms-auto mt-2 mb-2 border-0 p-2 lh-20 w250 bg-mini-gradiant text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                name="submintbtn2"
              >
                {isUpdateCard ? 'Update Card' : 'SUBSCRIBE'}
              </button>
            </div>
          </div>

        </div>
      </form>
      {loading ? <Loader /> : null}
    </>
  );
};
const PaymentForm = ({ setTermsError, termsChecked, goToSuccessPage }) => {

  const { loginRedirectUrl, showToast, subscriptionData } = useAuth();
  const navigate = useNavigate();
  const [useNewCard, setUseNewCard] = useState(false);

  const handleCheck = (e) => {
    setUseNewCard(e.target.checked);
  };

  const [loading, setLoading] = useState(false);
  const payNowByOldCard = () => {
    if (loading) return;
    setLoading(true);
    var data = new FormData();

    if (!termsChecked) {
      return setTermsError(true);
    }
    data.append("changeCard", 0);
    data.append("stripeToken", 'token');


    API.post({ route: endpoints.SUBSCRIPTION, data }).then(async (data) => {

      console.log("data -> ", data?.status, data)
      if (!data?.status) {
        showToast({
          text: data?.message,
          type: toastType.ERROR,
        });
        setLoading(false);
        return;
      }

      // const { type, clientSecret, subscription_mode, feed_id } = data?.result || {};
      if (data?.result) {
        showToast({
          text: 'Subscription renewed successfully',
        });
        navigate('/subscription-status')
        setTimeout(() => window.location.reload(), 300)
      }

    }).catch((e) => {
      console.log("error in card -> ", e?.response?.data, e)

      showToast({
        text: e?.response?.data?.message?.split('.')?.[0] ?? 'Wrong card entered',
        type: toastType.ERROR,
      });
      setLoading(false);
    })
      .finally(() => setLoading(false));
  }

  const params = useParams();
  const isUpdateCard = params?.type === 'new-card'

  if (!subscriptionData?.plan?.price_id) {
    return null
  }

  const payWithOldCard = () => {
    console.log("payWithOldCard");

    return <div>

      <div>
        <p className="pt-3 mb-0"> <b>Saved Card Details</b></p>
        <div className="flex items-center">
          <div>
            <label for="visa">
              XXXX-XXXX-XXXX-{subscriptionData?.subscription?.card?.last4} </label> (<span className="text-capitalize">{subscriptionData?.subscription?.card?.brand}</span>)
          </div>
        </div>
      </div>
      <div
        className={`agreement-checkbox p-0 cursor-pointer mt-4 mb-2`}
      >
        <input
          type="checkbox"
          className="mr-1 cursor-pointer"
          onChange={handleCheck}
          id="payWithOldCard"
        />
        <label
          htmlFor="payWithOldCard"
          className="cursor-pointer"
        >
          {" "}
          Pay From A Different Card
        </label>
      </div>
      {useNewCard ? null : <div className="card-submit mb-4">
        <button
          onClick={payNowByOldCard}
          className="ms-auto mt-2 mb-2 border-0 p-2 lh-20 w250 bg-mini-gradiant text-white text-center font-xssss fw-600 ls-1 rounded-xl"
          name="submintbtn2"
        >
          SUBSCRIBE
        </button>
      </div>}
    </div>
  }

  const hasCard = subscriptionData?.subscription?.card?.last4;
  // const hasCard = false



  return (

    <div>
      {hasCard && !isUpdateCard ? payWithOldCard() : null}
      {
        !hasCard || (hasCard && useNewCard) || isUpdateCard ? <Elements stripe={stripePromise}
          options={subscriptionData?.plan?.subscription_payment ? {
            mode: 'subscription',
            amount: parseFloat(subscriptionData?.plan?.amount || 0),
            currency: (subscriptionData?.plan?.currency || '')?.toString()?.toLowerCase(),
            priceId: subscriptionData?.plan?.price_id,
          } : {}}
        >
          <CheckoutForm cardUI={!hasCard || isUpdateCard} setTermsError={setTermsError} termsChecked={termsChecked} goToSuccessPage={goToSuccessPage} />
        </Elements> : null
      }
      {loading ? <Loader /> : null}
    </div>
  )
};
export default PaymentForm;
