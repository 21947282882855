import "./App.css";
import "./scss/main.scss";

import { Route, Routes, useLocation, useRoutes } from "react-router-dom";
import {
  AuthModal,
  Header,
  Loader,
  LocationModal,
  Sidebar,
} from "./components";
import { PremiumModal } from "./components/authorization/premium-modal";
import { useAuth } from "./context/Auth";
import Home from "./pages/Home";
import Subscription from "./pages/Subscription";
import MyMembership from "./pages/Static/my-membership";
import MyEvent from "./pages/Static/my-event";
import UserProfile from "./pages/UserProfile";
import EditProfile from "./pages/EditProfile";
import Calendar from "./pages/Calendar";
import Business from "./pages/Business";
import Contact from "./pages/Contact";
import FitnessDirectory from "./pages/FitnessDirectory";
import TermsAndConditions from "./pages/Static/terms-and-conditions";
import CodeOfEthics from "./pages/Static/code-of-ethics";
import ChangePassword from "./pages/ChangePassword";
import { GlobalLoader } from "./components/authorization/global-loader";
import { Toast } from "./components/authorization/global-toast";
import ReportCheating from "./pages/ReportCheating";
import Events from "./pages/Events";
import MembershipPaymentSuccess from "./pages/Static/membership-payment-success";
import EnterEvent from "./pages/EnterEvent";
import SingePost from "./pages/SingePost";
import Communicator from "./pages/Communicator";
import PrivacyPolicy from "./pages/Static/privacy-policy";
import Communication from "./pages/Communication";
import EnterEventSuccess from "./pages/Static/enter-event-success";
import ViewEvents from "./pages/ViewEvents";
import PayEvents from "./pages/PayEvents";
import EventPaymentSuccess from "./pages/Static/event-payment-success";
import ResetPassword from "./pages/ResetPassword";
import Invoices from "./pages/Invoices";
import { Messages } from "./components/messages";
import EventPaymentCancelPaypal from "./pages/Static/event-payment-cancel-paypal";
import ErrorPage from "./pages/Static/error";
import SubscriptionStatus from "./pages/SubscriptionStatus";
import EventManager from "./pages/EventManager";
import { isEventManager } from "./utils/utils";
import ForgotPassword from "./pages/ForgotPassword";
// import { Chat } from "./components/messages";

function Root() {
  const { loading, showLoginModal, showPremiumModal } = useAuth();

  const location = useLocation()
  const isCommunicator = location?.pathname === "/communicator"

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="main-wrapper">
        <Header isCommunicator={isCommunicator} />
        {isCommunicator ? null : <Sidebar />}
        <div className="main-content right-chat-active" style={isCommunicator ? { paddingLeft: 0 } : null}>
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0 ms-0 me-0">
              <div className="row">
                <div className="col-xl-12 p-5 font-xs pt-0 main-content-wrapper">
                  {isEventManager ?
                    <Routes>
                      <Route index element={<EventManager />} />
                      <Route path="/event-manager" element={<EventManager />} />
                      <Route path="/event-manager/competitors/:eventId" element={<EventManager />} />
                    </Routes> : <Routes>
                      <Route index element={<Home />} />

                      <Route
                        exact
                        path="/subscription/:type"
                        element={<Subscription />}
                      />
                      <Route
                        exact
                        path="/subscription"
                        element={<Subscription />}
                      />
                      <Route
                        exact
                        path="/my-membership"
                        element={<MyMembership />}
                      />
                      <Route exact path="/my-event" element={<MyEvent />} />
                      <Route
                        exact
                        path="/user/:userId"
                        element={<UserProfile />}
                      />
                      <Route
                        exact
                        path="/edit-profile"
                        element={<EditProfile />}
                      />
                      <Route
                        exact
                        path="/edit-profile/:isContinue"
                        element={<EditProfile />}
                      />
                      <Route exact path="/calendar" element={<Calendar />} />
                      <Route exact path="/business" element={<Business />} />
                      <Route exact path="/contact" element={<Contact />} />
                      <Route
                        exact
                        path="/terms-and-conditions"
                        element={<TermsAndConditions />}
                      />
                      <Route
                        exact
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                      />
                      <Route
                        exact
                        path="/code-of-ethics"
                        element={<CodeOfEthics />}
                      />
                      <Route
                        exact
                        path="/change-password"
                        element={<ChangePassword />}
                      />
                      <Route
                        exact
                        path="/forgot-password"
                        element={<ForgotPassword />}
                      />
                      <Route
                        exact
                        path="/reset-password"
                        element={<ResetPassword />}
                      />
                      <Route
                        exact
                        path="/communication"
                        element={<Communication />}
                      />
                      <Route
                        exact
                        path="/subscription-status"
                        element={<SubscriptionStatus />}
                      />
                      <Route
                        exact
                        path="/fitness-directory"
                        element={<FitnessDirectory />}
                      />
                      <Route
                        exact
                        path="/report-cheating"
                        element={<ReportCheating />}
                      />
                      <Route exact path="/events" element={<Events />} />
                      <Route exact path="/invoices" element={<Invoices />} />
                      <Route
                        exact
                        path="/view-events"
                        element={<ViewEvents />}
                      />
                      <Route
                        exact
                        path="/view-events/:editEventId"
                        element={<ViewEvents />}
                      />
                      <Route exact path="/pay-events" element={<PayEvents />} />
                      <Route exact path="/edit-events" element={<PayEvents />} />

                      <Route
                        exact
                        path="/event-payment-success-paypal"
                        element={<EventPaymentSuccess />}
                      />

                      <Route
                        exact
                        path="/event-payment-cancel-paypal"
                        element={<EventPaymentCancelPaypal />}
                      />

                      <Route exact path="/error" element={<ErrorPage />} />

                      <Route
                        exact
                        path="/event-payment-success/:eventName"
                        element={<EventPaymentSuccess />}
                      />

                      <Route
                        exact
                        path="/membership-payment-success/:membershipNumber/:expiryDate"
                        element={<MembershipPaymentSuccess />}
                      />
                      <Route
                        exact
                        path="/membership-payment-success/:membershipNumber/:expiryDate/:feedId"
                        element={<MembershipPaymentSuccess />}
                      />
                      <Route
                        exact
                        path="/enter-new-event"
                        element={<EnterEvent />}
                      />
                      <Route
                        exact
                        path="/edit-event/:editEventId"
                        element={<EnterEvent />}
                      />

                      <Route
                        exact
                        path="/post/:postId/:image/:content"
                        element={<SingePost />}
                      />
                      <Route
                        exact
                        path="/post/:postId"
                        element={<SingePost />}
                      />
                      {/* <Route
                        exact
                        path="/enter-event-success/:postId"
                        element={<EnterEventSuccess />}
                      /> */}
                      <Route
                        exact
                        // path="/enter-event-success/:postId/:eventId/:entrySuccessImage"
                        path="/enter-event-success/:postId/:eventId"
                        element={<EnterEventSuccess />}
                      />

                      <Route exact path="/messages" element={<Messages />} />
                      <Route exact path="/messages/:id" element={<Messages />} />
                      <Route exact path="/communicator" element={<Communicator />} />
                      <Route path="/event-manager" element={<EventManager />} />
                      <Route path="/event-manager/competitors/:eventId" element={<EventManager />} />
                    </Routes>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AuthModal show={showLoginModal} />
      <PremiumModal show={showPremiumModal} />
      <LocationModal />
      <GlobalLoader />
      <Toast />
      {/* <Chat /> */}
    </>
  );
}

export default Root;
