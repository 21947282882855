import { useEnterEvent } from "../../context/EnterEvent";

const TcConditions = () => {
  const { agreeRef, termsError, setTermChecked, setTermsError } =
    useEnterEvent();

  const handleTermsCheck = (e) => {
    setTermChecked(e.target.checked);
    if (e.target.checked) {
      setTermsError(false);
    }
  };

  return (
    <div class="card shadow-xss w-100 border-0 p-4 mb-3">
      <div class="card-body align-items-center p-0">
        <p className="text-current m-0">
          <strong>Step 5 of 6</strong>
        </p>

        <div className="agreement">
          <div className="agreement-title">
            <i class="fa-sharp fa-solid fa-file font-xxl text-current" />
            <h4 translate="ENTER_EVENT_DRUG_TITLE" className="mt-2">
              Participation Agreement &amp; Drug-Free Acknowledgment
            </h4>
          </div>
          <ol
            ng-class="{'text-right': langDirection==='rtl', 'text-left': langDirection==='ltr'}"
            className="text-left"
          >
            <li>
              <span
                className="text ng-scope"
                translate="ENTER_EVENT_DRUG_LINE1"
              >
                I consent to the use of any photographs, video recording or
                other images taken or recorded at this event by iCompete Natural
                or its nominees, in any magazine, broadcast transmission or any
                other printed or electronic media for the purposes of promoting
                this or future events, iCompete Natural or nominees or their
                sponsors.
              </span>
            </li>
            <li>
              <span
                className="text ng-scope"
                translate="ENTER_EVENT_DRUG_LINE2"
              >
                I fully understand a condition of entering this contest was I
                have not taken any banned substance for five (5) years prior to
                this competition. Further, I agree to provide a urine or blood
                sample for analysis for detection of substances prohibited on
                the World Anti-Doping Agency (WADA) list of Doping Classes and
                Methods and maintained by iCompete Natural (ICN).
              </span>
            </li>
            <li>
              <span
                className="text ng-scope"
                translate="ENTER_EVENT_DRUG_LINE3"
              >
                I realize it is each Athlete's personal duty to ensure that no
                Prohibited Substance enters his or her body. Athletes are
                responsible for any Prohibited Substance or its Metabolites or
                Markers found to be present in their bodily Specimens.
                Accordingly, it is not necessary that intent, fault, negligence
                or knowing Use on the Athlete's part be demonstrated to
                establish an anti-doping violation has occurred.
              </span>
            </li>
            <li ref={agreeRef}>
              <span
                className="text ng-scope"
                translate="ENTER_EVENT_DRUG_LINE4"
              >
                If I produce a positive test result to a prohibited substance,
                within 7 days I can elect to pay for B sample to verify the
                result. If I elect not to challenge the result of the A sample,
                or not reply within 7 days, I am acknowledging the result of a
                positive test and iCompete Natural will impose a sanction
                according to WADA guidelines.
              </span>
            </li>
            <li>
              <span
                className="text ng-scope"
                translate="ENTER_EVENT_DRUG_LINE5"
              >
                I will reimburse all the costs of my testing and all resulting
                consequences, including forfeiture of any placing, titles,
                financial benefits and return trophies and prizes within 7 days
                of such notification to do so.
              </span>
            </li>
            <li>
              <span
                className="text ng-scope"
                translate="ENTER_EVENT_DRUG_LINE6"
              >
                Decisions of iCompete Natural shall be final and enforceable,
                and that I will not bring any claim, arbitration, lawsuit or
                litigation in any other court or tribunal.
              </span>
            </li>
            <li>
              <span
                className="text ng-scope"
                translate="ENTER_EVENT_DRUG_LINE7"
              >
                I have read and understand this Acknowledgment and Agreement.
              </span>
            </li>
          </ol>
          <div
            className={`agreement-checkbox ${termsError ? "terms-error" : ""}`}
          >
            <input
              type="checkbox"
              className="mr-1"
              onChange={handleTermsCheck}
              id="conditions"
            />
            <label
              htmlFor="conditions"
              translate="ENTER_EVENT_DRUG_AGREE_LABEL"
              className="ng-scope"
            >
              {" "}
              I agree to the above conditions
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TcConditions;
