import moment from "moment";
import { useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API, endpoints } from "../api";
import { Confirmation } from "../components";
import { useAuth } from "../context/Auth";
import strings, { toastType } from "../utils/strings";
import { errorToString } from "../utils/utils";

/*{
  "plan": {
    "amount": 3500,
    "currency": "INR",
    "sign": "INR",
    "name": "india",
    "price_id": "price_1QY249HZyPheShh85rz6N5vE",
    "CodeCurrencyTypeID": 6,
    "subscription_payment": true, //TODO: This is recurring payment or not
  },
  "isSubscribed": true,//TODO: User is subscribed or not (This is main check)
  "subscription": {
    "status": false, //TODO:This subscription is on strip or not, if from strip then true otherwise false
    "card": []
  },
  "userSubscribed": 1,//TODO: user auto renewal subscription is active or not(will show cancel button on this check)
  "subscriptionMsg": "Your Premium Membership expires on 03 Apr 2025",
  "expiryDate": "2025-04-03 00:00:00",
  "membershipNumber": "98171032742222",
  "oldSubscribed": false //TODO: this is not in use now, I'll remove this
}*/

const SubscriptionStatus = () => {
  const { showToast, showGlobalLoader, checkSubscription, subscriptionData, user } =
    useAuth();

  const cancelSubscription = () => {
    showGlobalLoader(true);
    API.post({
      route: endpoints.CANCEL_SUBSCRIPTION,
    })
      .then((data) => {
        showToast({
          text: data?.message,
        });
        checkSubscription().finally(() => showGlobalLoader(false));
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
        showGlobalLoader(false);
      });
  };

  // Date to check
  const dateToCheck = moment(subscriptionData?.expiryDate, "YYYY-MM-DD HH:mm:ss");

  // Calculate the difference in years (absolute difference)
  const diffInYears = Math.abs(moment().diff(dateToCheck, 'years', true));

  // Check if the difference is less than or equal to 1 year
  const isWithinOneYear = diffInYears <= 1;

  useEffect(() => {
    checkSubscription();
  }, []);

  const navigate = useNavigate();

  const purchase = () => {
    if (
      user?.user?.first_name &&
      user?.user?.date_of_birth &&
      user?.user?.occupation &&
      user?.user?.country_code &&
      user?.user?.country
    ) {
      navigate("/subscription");
    } else {
      navigate("/edit-profile/continue");
      showToast({
        text: strings.completeProfile,
        type: toastType.ERROR,
      });
    }
  }

  const canCancel = useMemo(() => {
    if (subscriptionData?.subscription?.status) {//is it from strip or not
      return true;
    }

    return false;
  }, [subscriptionData]);

  const getText = useMemo(() => {
    if (subscriptionData?.subscriptionMsg) {
      return subscriptionData?.subscriptionMsg
    }
    return "Get access to all Premium Features..."
    // else if (moment(subscriptionData?.expiryDate).isSameOrAfter(new Date())) {
    //   if (subscriptionData?.subscription?.status) {
    //     //has auto renew
    //     return strings.subscription.autoRenew;
    //   } else {
    //     //subscription cancelled
    //     return strings.subscription.validTill;
    //   }
    // } else {
    //   return strings.subscription.expired;
    // }
  }, [subscriptionData]);

  return (
    <>
      <div className="content-page">
        <div class="card shadow-xss w-100 d-flex border-0 p-4 mb-3">
          <div class="card-body d-flex align-items-center p-0">
            <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900">
              <i class="text-white fa-solid fa-money-bill font-md me-2 btn-round-sm bg-blue-gradiant" />
              Membership
            </h2>
          </div>
        </div>
        <>
          <div class="card shadow-xss w-100 border-0 p-4">
            {subscriptionData?.subscription?.card?.brand ? <div>
              <div className="flex items-center mb-5">
                <div>
                  <label for="visa">
                    <span className="text-capitalize">{subscriptionData?.subscription?.card?.brand}</span>
                    {" "}
                    ending in {subscriptionData?.subscription?.card?.last4} </label>
                  <p className="ps-0">Expiry {subscriptionData?.subscription?.card?.exp_month}/{subscriptionData?.subscription?.card?.exp_year}</p>
                </div>

                <div class="flex">
                  <Link to={'/subscription/new-card'} type="button" class="edit-card-btn bg-blue-gradiant">Edit Card</Link>
                </div>

              </div>
            </div> : null}

            <div>
              {/* Your subscription is valid till{" "} */}
              <p className="ps-0 font-sm">{getText}{" "}</p>
              {/* {subscriptionData?.subscriptionMsg ? '' : moment(subscriptionData?.expiryDate).format("DD MMM yyyy")} */}
              {canCancel ? (subscriptionData?.isSubscribed && subscriptionData?.userSubscribed) ? (
                <>
                  <div className="form-group mt-5 mb-1 flex">
                    <Confirmation
                      btn={
                        <button className="w250 form-control text-center style2-input text-white fw-600 bg-mini-gradiant border-0 p-0">
                          Cancel Auto-Renew Subscription
                        </button>
                      }
                      title={`If you cancel, your premium membership will remain active until <b>${moment(subscriptionData.expiryDate).format('DD-MMM-YYYY')}</b> and will not renew. <br/> Please confirm the cancellation?`}
                      yesText="Yes"
                      noText="No"
                      yesClick={cancelSubscription}
                    />

                    {
                      isWithinOneYear ?
                        <button onClick={purchase} className="w200 ms-4 form-control text-center style2-input text-white fw-600 bg-cyan border-0 p-0">
                          Renew Membership
                        </button>
                        : null
                    }
                  </div>
                </>
              ) : <>
                <p className="ps-0 text-skype font-xss">You have cancelled your auto-renew subscription but can purchase another 365 days Membership anytime.</p>
                <div className="form-group mt-5 mb-1">
                  <button onClick={purchase} className="w200 form-control text-center style2-input text-white fw-600 bg-cyan border-0 p-0">
                    Purchase Membership
                  </button>
                </div>
              </> : (
                subscriptionData?.plan?.subscription_payment ? <div className="form-group mt-5 mb-1">
                  <button onClick={purchase} className="w200 form-control text-center style2-input text-white fw-600 bg-cyan border-0 p-0">
                    Purchase Membership
                  </button>
                </div> : (isWithinOneYear ? <div className="form-group mt-5 mb-1">
                  <button onClick={purchase} className="w200 form-control text-center style2-input text-white fw-600 bg-cyan border-0 p-0">
                    Renew Membership
                  </button>
                </div> : <div className="form-group mt-5 mb-1">
                  <button onClick={purchase} className="w200 form-control text-center style2-input text-white fw-600 bg-cyan border-0 p-0">
                    Purchase Membership
                  </button> </div>)
              )}


            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default SubscriptionStatus;
