import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/Auth";

export const SubNav = ({ name, data, blank, href, icon, text = '', link, onClick, textClass, anchorClass }) => {
  const [open, setOpen] = useState(false);

  const { setNavOpen } = useAuth();

  const Tag = link ? Link : "a";
  const aHref = link
    ? {
      to: href,
      onClick,
    }
    : {
      target: blank ? "_blank" : "_self",
      href,
      onClick,
    };

  return (
    <>
      <li onClick={() => !data?.length && setNavOpen(false)}>
        <Tag
          {...(data?.length
            ? {
              onClick: () => setOpen(!open),
            }
            : aHref)}
          className={`nav-content-bttn open-font cursor-pointer ${anchorClass ?? ''}`}
        >
          {icon ? <i className={`${icon} ${text ? 'text-icon-class' : ''}`}>{text}</i> : null}
          <span className={`text-grey-800 ${textClass ?? ''}`}>{name}</span>
          {data?.length ? (
            !open ? (
              <i className="me-3  text-primary fa-solid fa-caret-down font-xs float-right" />
            ) : (
              <i className="me-3  text-primary fa-solid fa-caret-up font-xs float-right" />
            )
          ) : null}
        </Tag>
      </li>
      {open && data?.map((item) => <SubNav {...item} />)}
    </>
  );
};
