import { useMemo, useState } from "react";
import { useEnterEvent } from "../../../context/EnterEvent";
import { Error } from "../../../components";
import "./style.scss";
import { useAuth } from "../../../context/Auth";
import { useNavigate } from "react-router-dom";
import strings, { toastType } from "../../../utils/strings";
import moment from "moment";

const EventSelect = ({
  title,
  type,
  headers = [
    {
      name: "Event",
      class: "col-lg-3",
      key: "eventTitle",
    },
    {
      name: "Venue",
      class: "col-lg-3 hidden-sm",
      key: "venue",
    },
    {
      name: "Location",
      class: "col-lg-2 hidden-sm",
      key: "location",
    },
    {
      name: "Entry Close",
      class: "col-lg-2 hidden-sm",
      key: "entriesCloseTime",
    },
    {
      name: "Event Starts",
      class: "col-lg-2 hidden-sm",
      key: "startTime",
    },
  ],
}) => {
  const { onEventSelect, selectedEvent, events, setConfirmation, editId } =
    useEnterEvent();
  const [open, setOpen] = useState(false);

  const { subscriptionData, user, showToast, userCheck } = useAuth();

  const filteredEvents = useMemo(
    () => events?.filter((event) => event?.[type]),
    [events, type]
  );

  const hasSelected = useMemo(
    () => editId || filteredEvents?.find(({ id }) => id === selectedEvent?.id),
    [editId, filteredEvents, selectedEvent]
  );

  const navigate = useNavigate();

  return (
    <>
      <div
        className={`event-select-overlay ${open ? "showing-select" : ""}`}
        onClick={() => setOpen(false)}
      />
      <div
        class={
          `card shadow-xss w-100 border-0 p-0 mb-3 mt-1 event-select${open ? " open" : ""} 
          ${hasSelected ? " selected" : ""} ${editId ? "disabled-event-select" : ""}`
        }
      >
        <div className="card-body">
          <div
            className="cursor"
            onClick={() =>
              userCheck(() => {
                !editId && setOpen(!open);
              })
            }
          >
            <div className="event-select-title">
              {(hasSelected && selectedEvent?.eventTitle) || title}
            </div>
            <i className="fa-solid fa-chevron-down e-select-arrow"></i>
          </div>
          <div className={`event-select-options`}>
            {filteredEvents?.length ? (
              <>
                {" "}
                <div className="row event-select-th">
                  {headers?.map(({ name, class: cls }) => (
                    <div
                      key={name.split(" ").join("")}
                      className={`col ${cls}`}
                    >
                      <strong>{name}</strong>
                    </div>
                  ))}
                </div>
                {filteredEvents?.map((event) => (
                  <div
                    key={event?.id}
                    className={`row custom-select-row ${selectedEvent?.id === event?.id ? "selected" : ""
                      }`}
                    onClick={() => {
                      if (editId) return;
                      if (subscriptionData?.isSubscribed) {
                        const subscriptionExpiryDate = subscriptionData?.expiryDate?.split(" ")?.[0]
                        const eventEndDate = event?.startTime?.split(" ")?.[0]

                        if (moment(subscriptionExpiryDate).isBefore(moment(eventEndDate))) {
                          setConfirmation({
                            title: "Your subscription will expire before the event.",
                            subTitle:
                              "Please renew your subscription to participate in this event.",
                            yesText: "Renew Subscription",
                            open: true,
                            singleBtn: true,
                            yesClick: () => {
                              setConfirmation({ open: false });
                              if (
                                user?.user?.first_name &&
                                user?.user?.date_of_birth &&
                                user?.user?.occupation &&
                                user?.user?.country_code &&
                                user?.user?.country
                              ) {
                                navigate("/subscription");
                              } else {
                                navigate("/edit-profile/continue");
                                showToast({
                                  text: strings.completeProfile,
                                  type: toastType.ERROR,
                                });
                              }
                            },
                          });
                          return
                        }
                        onEventSelect?.(event);
                      } else {
                        setConfirmation({
                          title: "",
                          subTitle:
                            "To enter a contest please upgrade to premium membership.",
                          yesText: "Ok",
                          open: true,
                          singleBtn: true,
                          yesClick: () => {
                            setConfirmation({ open: false });

                            if (
                              user?.user?.first_name &&
                              user?.user?.date_of_birth &&
                              user?.user?.occupation &&
                              user?.user?.country_code &&
                              user?.user?.country
                            ) {
                              navigate("/subscription");
                            } else {
                              navigate("/edit-profile/continue");
                              showToast({
                                text: strings.completeProfile,
                                type: toastType.ERROR,
                              });
                            }
                          },
                        });
                      }
                      setOpen(false);
                    }}
                  >
                    {headers?.map(({ key, class: cls }, i) => (
                      <div key={`${key}-${event?.id}`} className={`col ${cls}`}>
                        {i === 0 ? (
                          selectedEvent?.id === event?.id ? (
                            <i className="fa-regular fa-square-check mr-1 text-current"></i>
                          ) : (
                            <i className="fa-regular fa-square mr-1"></i>
                          )
                        ) : (
                          ""
                        )}
                        {key?.includes("Time")
                          ? moment(event[key]).format("DD MMM yyyy")
                          : event[key]}
                      </div>
                    ))}
                  </div>
                ))}
              </>
            ) : (
              <Error
                error="No events to show"
                bgClass="bg-none"
                textClass="text-red"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventSelect;
