import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Input,
} from "semantic-ui-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import Files from "react-files";
import { createTagDropdown } from "./hooks";
import "react-quill/dist/quill.snow.css";
import { useCommunicator } from "../../context/Communicator";
import { useAuth } from "../../context/Auth";
import { Confirmation } from "../../components";
import PubSub from 'pubsub-js'
const Parchment = Quill.import('parchment');

const initialFormData = {
  platform: 1,
  selfEmail: "1",
  emailBuddy: "",
  body: "",
  emailAddress: "",
};

const EmailTab = () => {
  const editorRef = useRef(null);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const {
    fromEmailAddressOptions,
    sendEmail,
    filteredFormData,
    recepientCount,
    uploadAttachments,
  } = useCommunicator();
  const [formData, setFormData] = useState(initialFormData);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      fromAddress: fromEmailAddressOptions?.[0]?.value
    }));
  }, [fromEmailAddressOptions])

  const modules = useMemo(() => ({
    // keyboard: {
    //   bindings: {
    //     custom: {
    //       key: 13, // Enter key
    //       handler: function (range, context) {
    //         const quill = editorRef.current?.getEditor();
    //         if (!quill) return;

    //         quill.insertEmbed(range.index, 'text', '\n\n');
    //         quill.setSelection(range.index + 1);

    //         // Prevent the default behavior
    //         return false;
    //       },
    //     },
    //   },
    // },
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block", 'link'],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: "" }, { align: "right" }, { align: "center" }, { align: "justify" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ script: "sub" }, { script: "super" }],
        [{ color: [] }, { background: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ tags: ["{firstName}", "{lastName}", "{membershipNumber}"] }], // custom dropdown
      ],
      handlers: {
        tags: tagsHandler,
      },
    },
  }), []);

  useEffect(() => {
    editorRef?.current?.getEditor()?.root?.addEventListener('click', (ev) => {
      const image = Parchment.find(ev.target);
      if (image?.statics?.blotName === 'image') {
        editorRef?.current?.getEditor()?.setSelection(image?.offset(editorRef?.current?.getEditor()?.scroll), 1, 'user');
      }
    });
    createTagDropdown(editorRef?.current)
  }, [editorRef]);

  function tagsHandler(value) {
    const cursorPosition = this.quill.getSelection().index;
    this.quill.insertText(cursorPosition, value);
    this.quill.setSelection(cursorPosition + value.length);
  }

  const handleQuillChange = (body) => {
    setFormData((prev) => ({
      ...prev,
      body,
    }));
  };

  const uploadFiles = useCallback(
    async (newFiles) => {
      const fd = new FormData();

      newFiles.forEach((file) => {
        fd.append("attachments[]", file);
      });

      setUploadFileLoading(true);

      try {
        const results = await uploadAttachments(fd);
        if (results.result.length) {
          console.log("results.result => ", results.result)
          setFiles((prev) => ([
            ...prev,
            ...results.result,
          ])
          );
        }
        // setFormData((prev) => ({
        //   ...prev,
        //   body: '',
        // }));
        setUploadFileLoading(false);
      } catch (err) {
        setUploadFileLoading(false);
      }
    },
    [uploadAttachments]
  );

  const handleFileChange = (newFiles) => {
    uploadFiles(newFiles);
  };

  const handleFileRemove = (fileId) => {
    setFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile.id !== fileId)
    );
  };

  const handleFileInsert = (file) => {
    const quill = editorRef.current.getEditor();
    const selection = quill.getSelection();
    quill.insertEmbed(selection ? selection.index : 0, "image", file.filePath);
  };

  const handleChange = (e, d) => {
    setFormData((prev) => ({
      ...prev,
      [d.name]: d.value,
    }));
  };
  const handleToggle = (e, d) => {//add here
    console.log("handleToggle ->  ", d.name, d.checked)

    if (d.name === 'emailBuddy') {
      PubSub.publish('GET_COUNT', { emailBuddy: d.checked ? 1 : 0 })
    }
    if (d.name === 'selfEmail') {
      PubSub.publish('ME_SELECT', d.checked)
    }
    setFormData((prev) => ({
      ...prev,
      [d.name]: d.checked ? "1" : "0",
    }));
  };

  const { showToast } = useAuth();

  const handleSubmit = async (e) => {

    const payload = { ...formData, ...filteredFormData };

    if (!payload?.emailSubject) {
      return showToast({
        text: 'Please enter email subject',
        type: "error",
      });
    }

    // payload.body = payload?.body?.split('\n').join('<br />');

    if (!payload?.body || payload?.body == '<p><br></p>') {
      return showToast({
        text: 'Please enter email body',
        type: "error",
      });
    }

    payload.body = payload.body.split('<p>').join('<p style="margin:0;padding:0">')


    const newFiles = files?.map((file) => ({ ...file, isAttachment: !payload?.body?.includes(file?.fileName) }))
    payload.attachments = newFiles;

    setSendEmailLoading(true);
    try {
      await sendEmail(payload);
      setSendEmailLoading(false);
      setFiles([])
      setFormData((prev) => ({
        ...prev,
        body: "",
        emailSubject: ''
      }));
    } catch (err) {
      setSendEmailLoading(false);
    }
  };

  console.log("files => ", files)

  return (
    <div className="communicator__email-tab">
      <Form>
        <Grid>
          <Grid.Column width={4} className="text-right">
            <label>From Address</label>
          </Grid.Column>
          <Grid.Column width={8}>
            <Dropdown
              placeholder="No-Reply"
              fluid
              selection
              options={fromEmailAddressOptions.map(option => ({
                key: option.value,
                text: `${option.title} (${option.key})`,
                value: option.value
              }))}
              clearable
              name={"fromAddress"}
              value={formData.fromAddress || fromEmailAddressOptions?.[0]?.value}
              defaultValue={formData.fromAddress || fromEmailAddressOptions?.[0]?.value}
              onChange={handleChange}
              required
            />
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column width={4} className="text-right">
            <Form.Field
              control={Checkbox}
              label="Email Me Too"
              name="selfEmail"
              value={formData.selfEmail}
              onChange={handleToggle}
              defaultChecked
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Field
              control={Checkbox}
              label="Email Buddy"
              name="emailBuddy"
              value={formData.emailBuddy}
              onChange={handleToggle}
            />
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column width={4} className="text-right">
            <label className="font-bold">Email Subject</label>
          </Grid.Column>
          <Grid.Column width={8}>
            <Input
              className="custom-placeholder"
              placeholder="Enter subject line here"
              fluid
              name="emailSubject"
              required
              value={formData.emailSubject}
              defaultValue={formData.emailSubject}
              onChange={handleChange}
            />
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column width={4} className="text-right">
            <label>Add Attachment</label>
          </Grid.Column>
          <Grid.Column width={12}>
            <Files
              className="files-dropzone"
              onChange={handleFileChange}
              onError={(error) => { }}
              accepts={["image/*", "application/pdf", 'video/*']}
              multiple
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              <Button
                type="button"
                color="grey"
                loading={uploadFileLoading}
                disabled={uploadFileLoading}
              >
                Upload File
              </Button>
            </Files>

            {files.length > 0 && (
              <div>
                <ul className="files-list">
                  {files.map((file) => (
                    <li key={file.id} className="files-list-item">
                      <div className="files-list-item-content">
                        <span className="files-list-item-content-item files-list-item-content-item-1">
                          {file.fileNameOriginal}
                        </span>
                        {
                          file.fileNameOriginal?.includes('.pdf') ? null : <a
                            href="/"
                            className="p-2 files-list-item-remove"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFileInsert(file);
                            }}
                          >
                            Insert in email
                          </a>
                        }
                        <a
                          href="/"
                          className="p-1 files-list-item-remove"
                          onClick={(e) => {
                            e.preventDefault();
                            handleFileRemove(file.id);
                          }}
                        >
                          Remove attachment
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column width={16}>
            <div className="email-editor notranslate">
              <ReactQuill
                value={formData.body}
                onChange={handleQuillChange}
                ref={editorRef}
                modules={modules}
                theme="snow"
                required
              />
            </div>
          </Grid.Column>
          <Grid.Column width={3} floated="right">


            {sendEmailLoading || !recepientCount || !formData.emailSubject || !formData.body || formData?.body === '<p><br></p>' ? (
              <Button
                type="submit"
                className="btn-submit"
                fluid
                color="red"
                loading={sendEmailLoading}
                disabled
              >
                Send Email
              </Button>
            ) : (
              <Confirmation
                btn={
                  <Button
                    type="submit"
                    className="btn-submit"
                    fluid
                    color="red"
                    loading={sendEmailLoading}
                  >
                    Send Email
                  </Button>
                }
                title={`Are you sure you want to send email to ${recepientCount} user(s)`}
                yesText="Yes Send"
                noText="No"
                yesClick={handleSubmit}
              />
            )}

          </Grid.Column>
        </Grid>
      </Form>
    </div>
  );
};

export default EmailTab;
